<template>
    <div class="card">
        <div class="bg-blue-light p-2">
            <TitleButton
                :showBtn="false"
                btnTitle="Add New"
                title="Project wise Cash & Bank Balance Summary"
            />
        </div>

        <div class="mt-2 px-2">
            <ProjectWiseCashBankBalanceTable
                :tableData="projectCashBankBalance"
            />
        </div>
        <GlobalLoader />
    </div>
</template>

<script setup>
import {onMounted} from 'vue';
import {useRoute} from 'vue-router'
import TitleButton from '@/components/atom/TitleButton'
import ProjectWiseCashBankBalanceTable from '@/components/molecule/company/report/ProjectWiseCashBankBalanceTable.vue'
import useReport from '@/services/modules/dashboard/report.js'

const {projectCashBankBalance, fetchProjectWiseCashBankBalance} = useReport()
const route = useRoute()
onMounted(() => {
  fetchProjectWiseCashBankBalance(getQuery())
})
function getQuery () {
  return '?company_id=' + route.params.companyId
}

</script>
